import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import Text from "@ui/text";
import Heading from "@ui/heading";
import Image from "@ui/image";
import {
  TrManagementKeyFeaturesWrapper,
  TrManagementKeyFeaturesInner,
  GamesInfoBox,
  GamesInfoInner,
  GamesInfoIconwarp,
  GamesInfoTextwrap,
} from "./style";

export default function TrManagementKeyFeatures({
  headingStyle,
  descStyle,
  data,
}) {
  return (
    <TrManagementKeyFeaturesWrapper>
      <TrManagementKeyFeaturesInner>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle
                subtitle={data.section_title.subtitle}
                title={data.section_title.title}
                description={data.section_title.description}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <GamesInfoBox>
                {data.items.map((el, index) => (
                  <GamesInfoInner key={index}>
                    <GamesInfoIconwarp>
                      <Image src={el.images[0].src} alt="high" />
                    </GamesInfoIconwarp>
                    <GamesInfoTextwrap>
                      <Heading {...headingStyle}>{el.title}</Heading>
                      <Text {...descStyle}>{el.description}</Text>
                    </GamesInfoTextwrap>
                  </GamesInfoInner>
                ))}
              </GamesInfoBox>
            </Col>
          </Row>
        </Container>
      </TrManagementKeyFeaturesInner>
    </TrManagementKeyFeaturesWrapper>
  );
}
TrManagementKeyFeatures.propTypes = {
  textStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  descStyle: PropTypes.object,
};

TrManagementKeyFeatures.defaultProps = {
  textStyle: {
    fontweight: 700,
  },
  headingStyle: {
    as: "h4",
    color: "#fff",
    fontSize: "22px",
    fontweight: 700,
    mb: "40px",
  },
  descStyle: {
    color: "#fff",
  },
};
