import styled, { themeGet, device } from "@styled";

export const TrManagementKeyFeaturesWrapper = styled.section`
  padding: 100px 0;
  background: linear-gradient(
    320deg,
    ${themeGet("colors.secondaryDark")},
    ${themeGet("colors.secondary")}
  );
`;
export const TrManagementKeyFeaturesInner = styled.div``;
export const GamesInfoBox = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
export const GamesInfoInner = styled.div`
  flex: 1 1 100%;
  margin: 45px 10px;
  max-width: 400px;
  background: rgb(255 255 255 / 11%);
  padding: 50px 15px 40px;
  border-radius: 4px;
  border: 1px solid #8e7aa5;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  transition: ${themeGet("transition")};
  &:hover {
    box-shadow: 0 0 40px -16px rgb(255 255 255);
    transform: translateY(-3px);
  }
  ${device.medium} {
    flex: 1 1 47%;
  }
  ${device.xlarge} {
    flex: 1 1 29%;
  }
`;
export const GamesInfoIconwarp = styled.div`
  position: absolute;
  bottom: calc(100% + -28px);
  background: #fff;
  width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 20%);
`;
export const GamesInfoTextwrap = styled.div`
  h4 {
    min-height: 52px;
  }
`;
