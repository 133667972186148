import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";
import Button from "@ui/button";
import Text from "@ui/text";
import Heading from "@ui/heading";
import {
  IntroWrapper,
  LeftBox,
  RightBox,
  ContentWarp,
  ButtonWrap,
} from "./stye";

const IntroArea = ({ data, layout, ...props }) => {

  return (
    <IntroWrapper layout={layout} {...props} id={data.section}>
      <Container>
        <Row>
          <Col lg={7}>
            <LeftBox>
              <SectionTitle
                subtitle={data.section_title?.subtitle}
                title={data.section_title?.title}
                description={data.section_title?.description}
              />

              {data &&
                data.items?.map((el, index) => (
                    <ContentWarp key={index}>
                      {el && el.title && <Heading as="h5">{el.title}</Heading>}
                      {el && el.description && <Text>{el.description}</Text>}
                    </ContentWarp>
                ))}
              {data?.buttons?.map(({ id,index1, content, ...rest }) => (
                <ButtonWrap key={id}>
                  <Button m="7px" {...rest}>
                    {content}
                  </Button>
                </ButtonWrap>
              ))}
            </LeftBox>
          </Col>
          <Col lg={5}>
            <RightBox>
              <Image
                src={data.images[0]?.src}
                alt={data.images[0]?.alt || "Info"}
                title={data.images[0]?.alt || "Info"}
              />
            </RightBox>
          </Col>
        </Row>
      </Container>
    </IntroWrapper>
  );
};

IntroArea.propTypes = {
  layout: PropTypes.oneOf([1, 2]),
};
IntroArea.defaultProps = {
  layout: 1,
};
export default IntroArea;
